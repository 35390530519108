import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './assets/css/base/__custom__.scss';
import './assets/css/base/root.css';
import './assets/css/base/color.css';
import './assets/css/base/font.css';
import './assets/css/base/overlay.css';
import './assets/css/base/width.css';
import './assets/css/base/height.css';
import './assets/css/base/svg.css';
import './assets/css/base/border.css';
import './assets/css/base/transform.css';
import './assets/css/base/responsive.css';

import './assets/css/_root_.css';

import CONF from './config';

import HamannCorp from './pages/HamannCorp';
import Simulador from './pages/Simulador';
import Bio from './pages/Bio';
import Privacidade from './pages/Privacidade';
import FAQ from './pages/FAQ';

import PackageProvider from './context/PackageProvider';

import { Wrapper } from './components/wookie';

export default function App() {
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
    const savedTheme = localStorage.getItem('theme') || mediaQuery;
    if (savedTheme) {
      document.documentElement.className = savedTheme;
    }
  }, []);

  return (
    <PackageProvider>
      <Routes>
        <Route
          path='/bio'
          element={<Bio />}
        />
        <Route
          path='/thanks'
          element={
            <Wrapper
              text='Recebemos suas informações e entraremos em contato em breve'
              clsnmText='fw500 fs16'
              textHeader='Obrigado!'
              conf={CONF}
            />
          }
        />
        <Route
          path='/simulador'
          element={<Simulador />}
        />
        <Route
          path='/simulacao-online-de-financiamento'
          element={<Simulador />}
        />
        <Route
          path='/simulacao-de-financiamento-imobiliario-online'
          element={<Simulador />}
        />

        <Route
          path='/meu-financiamento'
          element={<Simulador />}
        />
        <Route
          path='/simule'
          element={<Simulador full={false} />}
        />
        <Route
          path='/simule-agora-seu-financiamento'
          element={<Simulador full={false} />}
        />
        <Route
          path='/faq'
          element={<FAQ />}
        />
        <Route
          path='/perguntas-frequentes'
          element={<FAQ />}
        />
        <Route
          path='/privacidade'
          element={<Privacidade />}
        />
        <Route
          exact
          path='/'
          element={<HamannCorp />}
        />
        <Route
          exact
          path='/404'
          name='Page 404'
          element={
            <Wrapper
              text='Ooops! Página não encontrada'
              clsnmText='fw-500 fs-16'
              textHeader='Erro 404'
              conf={CONF}
            />
          }
        />
        <Route
          exact
          path='/500'
          name='Page 500'
          element={
            <Wrapper
              text='Acesso não permitido'
              clsnmText='fw-500 fs-16'
              textHeader='Erro 500'
              conf={CONF}
            />
          }
        />
        <Route
          path='*'
          element={
            <Wrapper
              text='Ooops! Página não encontrada'
              clsnmText='fw-500 fs-16'
              textHeader='Erro 404'
              conf={CONF}
            />
          }
        />
      </Routes>
    </PackageProvider>
  );
}
