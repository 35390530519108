import {
  CONF,
  foto01,
  mrbean,
  services,
} from '../config/imports';

import Faq from '../components/common/Faq';
import Flow from '../components/Flow';
import Footer from '../components/common/Footer';
import NavBar from '../components/common/NavBar';

import {
  ContainerBox,
  Hero,
  CardBoxLine,
  BoxImageText,
  BoxTextImageInside,
} from '../components/wookie';

import * as webContent  from '../content';

export default function HamannCorp() {

  const headline = () => {
    return (
      <>
        Seu sonho de ter um{' '}
        <span className='cYellow'>
          <b>imóvel próprio</b>
        </span>{' '}
        está mais perto do que você imagina
      </>
    );
  };

  const textBox = () => {
    const paragraphs = webContent.textBox;
    return paragraphs.map((item, index) => {
      return <p key={`textBox_pgs_${index}`}>{item}</p>;
    });
  };

  const returnMrBean = () => {
    return (
      <>
        <h2 className='fw800 textContrastColor ffSoft'>
          Qual é o melhor momento para comprar um imóvel?
        </h2>
        <p className='fw500 fs30'>
          O melhor momento é sempre{' '}
          <span className='textContrastColor fw600'>AGORA</span>!
        </p>
        <p className='mt-2'>
          As taxas vem crescendo ao longo dos anos, e a perspectiva para os
          próximos é que mantenha esse mesmo padrão.
        </p>
        <p>
          Por esse motivo, o imóvel que você hesita em comprar hoje, vai custar
          mais caro amanhã. Se está nos seus projetos comprar um imóvel, fale
          conosco e vamos juntos <b>encontrar a solução</b>!
        </p>

        <p>
          E você, que hesita em comprar, e continua no aluguel... vai deixar o
          proprietário do imóvel feliz da vida, quitando mais um bem com o
          dinheiro do aluguel que você paga.
        </p>
      </>
    );
  };

  return (
    <>
      <ContainerBox
        main={
          <>
            <Hero
              clsnm='hero'
              clsnmbg='bghero bghero_01'
              overlay='py-5 bgOverlayDark heroContent'
              applyWidth='setWidth'
              slogan={headline()}
              sloganClass='lh10 fw700 cWhite'
              sloganSubText='Nós temos a melhor consultoria em financiamento imobiliário no Vale do Paraíba'
              sloganSubTextClass='mt-1 lh11 fw500 fs05Mob cYellow'
              form={true}
              formTitle='Fale conosco agora'
              formClass='wpMax500'
              formColorMode='dark'
              formConf={CONF}
              formCreateLead={services.createLead}
              zoomSlogan={true}
            />

            <h1 className='mt-5 fw600 fs20Mob textContrastColor text-center px-3'>
              Só a <b>melhor consultoria</b> em financiamento imobiliário tem:
            </h1>
            <CardBoxLine cards={webContent.cardsDif} />

            <h2 className='mt-5 fs30 fs20Mob fw600 textContrastColor text-center px-3'>
              Segurança e Confiabilidade
            </h2>
            <BoxImageText
              image={foto01}
              text={textBox()}
            />

            <CardBoxLine cards={webContent.cardsProds} />

            <BoxTextImageInside
              image={mrbean}
              text={returnMrBean()}
            />

            <Flow />
            
            <Faq mobile={true} />
          </>
        }
        footer={<Footer />}
        navbar={<NavBar />}
        overlayMain={false}
        classMain=''
      />
    </>
  );
}
